<template>
    <el-dialog :title="title"
               :visible="visible"
               :close-on-click-modal="false"
               width="1100px"
               @close="close">
        <div>
            <el-row>
                <el-col :span="4">货号</el-col>
                <el-col :span="20">颜色/尺码</el-col>
            </el-row>
            <el-row v-for="(detail, index) in data.DetailList" :key="detail.ItemNo + index">
                <el-col :span="4">
                    <el-checkbox v-model="detail.IsNoneStock" @change="itemNoChkChange(detail)" label="断" border />{{ detail.ItemNo }}
                </el-col>
                <el-col :span="20">
                    <el-row v-for="(colorDetail, colorIndex) in detail.ColorDetails"
                            :key="detail.ItemNo + colorDetail.Color + colorIndex">
                        <el-col :span="3">
                            <el-checkbox v-model="colorDetail.IsNoneStock" @change="colorChkChange(colorDetail)" label="断" border />{{
                                colorDetail.Color
                            }}
                        </el-col>
                        <el-col :span="21" class="size-container">
                            <el-tag :title="detail.ItemNo + colorDetail.Color + sizeDetail.Size" class="size-tag" v-for="(sizeDetail, sizeIndex) in colorDetail.SizeDetails"
                                    :key="detail.ItemNo + colorDetail.Color + sizeDetail.Size + sizeIndex">
                                <el-checkbox v-model="sizeDetail.IsNoneStock" :disabled="sizeDetail.sizeChkDisabled==true" @change="sizeChkChange(sizeDetail)" label="断" border />
                                {{ sizeDetail.Size }}*{{ sizeDetail.Num }}
                                缺货
                                <el-input class="out-stock" v-model="sizeDetail.OutStockNum" :blur="inputBlur(sizeDetail)" :disabled="sizeDetail.IsNoneStock" @input="changeInputNum(sizeDetail)"
                                          onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                            </el-tag>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="hasStock()">全部有货</el-button>
            <el-button type="primary" @click="noneStock()">缺/断货</el-button>
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { successCode, tokenName } from "@/config";
    import { submit } from "@/api/base";

    export default {
        name: "MergePending",
        components: {},
        data() {
            return {
                visible: false,
                type: "",
                title: "",
                data: [],
                headers: {
                    Authorization: "Bearer " + this.$store.getters["user/accessToken"],
                },
            };
        },
        methods: {
            init(data) {
                this.visible = true;
                this.title = "合并处理";
                this.data = data;
            },
            itemNoChkChange(detail) {
                const that = this;
                detail.ColorDetails.forEach((colorDetail) => {
                    colorDetail.IsNoneStock = detail.IsNoneStock;
                    that.colorChkChange(colorDetail);
                });
            },
            colorChkChange(colorDetail) {
                colorDetail.SizeDetails.forEach((sizeDetail) => {
                    sizeDetail.IsNoneStock = colorDetail.IsNoneStock;
                    sizeDetail.sizeChkDisabled = colorDetail.IsNoneStock;
                    if (colorDetail.IsNoneStock) {
                        sizeDetail.OutStockNum = 0;
                    }
                });
            },
            sizeChkChange(sizeDetail) {
                if (sizeDetail.IsNoneStock) {
                    sizeDetail.OutStockNum = 0;
                }
            },
            inputBlur(sizeDetail) {
                if (sizeDetail.OutStockNum == "") {
                    sizeDetail.OutStockNum = 0;
                }
            },
            changeInputNum(sizeDetail) {

                if (sizeDetail.OutStockNum > 0) {
                    sizeDetail.IsNoneStock = false;
                    sizeDetail.sizeChkDisabled = true;
                } else {
                    sizeDetail.sizeChkDisabled = false;
                }
            },
            async hasStock() {
                const _this = this;
                _this.$baseConfirm(`确定当前报单全部有货并提交至待配货？`, null, async () => {
                    const ids = _this.data.TradePendingIdList.join(",");
                    const { msg } = await submit("/api/stallPending/updateTradeStock", {
                        TradePendingIDs: ids,
                        HasStock: true
                    });
                    if (msg) {
                        _this.$baseMessageBox(
                            `全部有货设置成功，${msg}`,
                            `提示`
                        );
                    } else {
                        _this.$baseMessage(`全部有货设置成功`, "success");
                    }
                    _this.handleQuery();
                });
            },
            async noneStock() {
                const _this = this;
                const request = {
                    TradePendingIDs: _this.data.TradePendingIdList.join(","),
                    HasStock: false,
                    StockDetails: []
                };
                _this.data.DetailList.forEach((detail) => {
                    if (detail.IsNoneStock) {
                        request.StockDetails.push({
                            ItemNo: detail.ItemNo, NoneStock: true
                        });
                    } else {
                        detail.ColorDetails.forEach((colorDetail) => {
                            if (colorDetail.IsNoneStock) {
                                request.StockDetails.push({
                                    ItemNo: detail.ItemNo, Color: colorDetail.Color, NoneStock: true
                                });
                            } else {
                                colorDetail.SizeDetails.forEach((sizeDetail) => {
                                    if (sizeDetail.IsNoneStock) {
                                        request.StockDetails.push({
                                            ItemNo: detail.ItemNo, Color: colorDetail.Color, Size: sizeDetail.Size, NoneStock: true
                                        });
                                    } else {
                                        if (sizeDetail.OutStockNum > 0) {
                                            request.StockDetails.push({
                                                ItemNo: detail.ItemNo, Color: colorDetail.Color, Size: sizeDetail.Size, OutStockNum: sizeDetail.OutStockNum
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
                if (request.StockDetails.length <= 0) {
                    _this.$baseMessage("请填写缺/断货信息", "error");
                    return;
                }
                _this.$baseConfirm(`确定当前报单缺/断货信息并提交至待配货？`, null, async () => {
                    const { msg } = await submit("/api/stallPending/updateTradeStock", request);
                    if (msg) {
                        _this.$baseMessageBox(
                            `缺/断货设置成功，${msg}`,
                            `提示`
                        );
                    } else {
                        _this.$baseMessage(`缺/断货设置成功`, "success");
                    }
                    _this.handleQuery();
                });
            },
            close() {
                this.$refs.data = {};
                this.visible = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .editForm {
        border: 1px solid #ebeef5;
        padding-top: 10px;
        margin-bottom: 10px;
    }

    .operation {
        border: 1px solid #ebeef5;
        padding: 5px;
        margin-bottom: 10px;

        > div {
            display: inline-block;
            margin: 0 10px;
        }
    }

    .size-container {
        text-align: left;
        white-space: break-spaces;

        .size-tag:first-child {
            margin-left: 10px;
        }

        .size-tag {
            font-size: 14px;
            height: 30px;
            margin-bottom: 5px;

            .out-stock {
                width: 50px;

                ::v-deep {
                    .el-input__inner {
                        height: 25px !important;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
</style>
